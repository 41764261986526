.sb-site-container {
    background-image: url(../img/back.png);
    background-repeat: repeat;
}

.boxed {
    background-color: #fff;
    max-width: 1400px;
    margin: 0 auto !important;
    border-left: solid 1px #ccc;
    border-right: solid 1px #ccc;
    -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.4), inset 0 0 2px rgba(255,255,255,.75);
    -moz-box-shadow: 0 1px 2px rgba(0,0,0,.4), inset 0 0 2px rgba(255,255,255,.75);
    box-shadow: 0 1px 2px rgba(0,0,0,.4), inset 0 0 2px rgba(255,255,255,.75);
}
